<template>
  <div>
    <div class="container">
      <div class="logo">
        <img src="@/assets/logo.png" alt="" />
      </div>
      <h2>Registrarse</h2>
      <div class="card">
        <form>
          <div class="mb-3">
            <label class="form-label">Nombres</label>
            <input type="text" class="form-control" v-model="nombres" />
          </div>
          <div class="mb-3">
            <label class="form-label">Apellidos</label>
            <input type="text" class="form-control" v-model="apellidos" />
          </div>
          <div class="mb-3">
            <label class="form-label">Correo electrónico</label>
            <input
              type="email"
              class="form-control"
              id="exampleInputEmail1"
              aria-describedby="prueba"
              v-model="email"
            />
            <!-- <div id="emailHelp" class="form-text">
              {{ mensaje_error }}
            </div> -->
          </div>
          <div class="mb-3">
            <label class="form-label">Contraseña</label>
            <input
              type="password"
              class="form-control"
              id="exampleInputPassword1"
              v-model="password"
            />
          </div>
          <div class="mb-3">
            <label class="form-label">Sede</label>
            <select
              id="inputState"
              class="form-select"
              v-model="sede"
              @change="sedeId(sede)"
            >
              <option selected>Por favor seleccione una sede</option>
              <option v-for="item in sedes" :key="item.id">
                {{ item.nombre }}
              </option>
            </select>
          </div>
          <button type="button" class="btn btn-success" @click="register()">
            Ingresar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      nombres: "",
      apellidos: "",
      email: "",
      password: "",
      sede: "",
      sedes: [],
      mensaje_error: "",
      sedeId_: "",
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getSedes();
  },
  methods: {
    getSedes() {
      let self = this;
      //  let config = this.configHeader();
      axios
        .get(self.URL_API+"sede")
        .then(function (result) {
          try {
            // console.log(result.data);
            self.sedes = result.data;
          } catch (error) {
            console.log(error);
          }
        });
    },
    register() {
      let self = this;
      let user = {
        nombres: this.nombres,
        apellidos: this.apellidos,
        email: this.email,
        password: this.password,
        id_sede: this.sedeId_,
      };
      axios
        .post(self.URL_API+"register", user)
        .then(function (result) {
          try {
            if (result.data.status == "error") {
              console.log("error");
              self.message("error", result.data.message);
            }else{
                self.$router.push("/");
            }
            
          } catch (error) {
            console.log(error);
          }
        });
    },
    sedeId(sede) {
      let self = this;
      var cont = 0;
      this.sedes.forEach(function (element) {
        if (sede == element.nombre) {
          self.sedeId_ = element.id;
          cont++;
        }
      });
      if (cont <= 0) {
        self.sedeId_ = "";
      }
    },
    message(icon, title) {
      this.$swal.fire({
        position: "top",
        icon: icon,
        title: title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
  },
};
</script>
<style scoped>
.card {
  width: 35%;
  height: 520px;
  margin: auto;
  padding: 20px;
  background-color: rgba(239, 237, 237, 0.642);
  min-width: 350px;
  margin-bottom: 30px;
}

label {
  float: left;
}

.form-control {
  background-color: white;
}

h2 {
  font-family: "Montserrat", sans-serif;
  margin: 20px 0px 20px 0px;
}

button {
  width: 100%;
}
.logo {
  width: 150px;
  margin: auto;
  border-radius: 15px;
  margin-top: 50px;
}
.logo img {
  width: 100%;
  border-radius: 15px;
}

#emailHelp {
  color: red;
}
</style>
