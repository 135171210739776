<template>
  <div>
    <Navbar />
    <div class="container">
      <div class="row">
        <button
          type="button"
          id="newNews"
          class="col-2 btn btn-success"
          @click="newPerson()"
          v-if="btn_ingreso_persona"
        >
          <i class="bi bi-file-earmark-plus"></i> Ingresar persona
        </button>
        <button
          type="button"
          id="newNews"
          class="col-2 btn btn-success"
          @click="newPerson2()"
          v-if="btn_persona_ingreso"
          style="margin-left:10px"
        >
          <i class="bi bi-file-earmark-plus"></i> Ingresar persona
        </button>
        <form id="search" class="col-6 d-flex" role="search">
          <input
            class="form-control me-2"
            type="search"
            placeholder=" Buscar"
            aria-label="Search"
            v-model="search"
          />
          <button type="button" class="btn btn-outline-success" @click="searchWord(search)">Buscar</button>
        </form>
        <button type="button" id="reset" class="col-2 btn btn-success" @click="getPersonas(), (search = '')">
          <!-- @click="getPersonas(), (search = '')" -->
          <i class="bi bi-arrow-counterclockwise"></i> Borrar busqueda
        </button>
      </div>
      <h3>Permanencia albergues</h3>
      <h6>Familias: {{familias.length}}</h6>
      <h6>Personas: {{numeroPersonas.length}}</h6>
      <h6>Costo manutención total ahora: {{convertirMoneda(valor_total_dia)}}</h6>
      <!-- <div class="card"> -->
      <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover">
        <thead>
          <tr>
            <th scope="col">Codigo de familia</th>
            <th scope="col">Tipo documento</th>
            <th scope="col">Documento</th>
            <th scope="col">Nombres</th>
            <th scope="col">Apellidos</th>
            <th scope="col">Código fud</th>
            <th scope="col">Fecha ingreso</th>
            <th scope="col">Hora ingreso</th>
            <th scope="col">Fecha salida</th>
            <th scope="col">Hora salida</th>
            <th scope="col">valor persona ahora</th>
            <th scope="col">Municipio de ocurrencia</th>
            <th scope="col">Entidad de declararación</th>
            <th colspan="2" scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in personas" :key="item.id">
            <th :style="item.fecha_salida_albergue === '' ? 'color:#28B463':typeof item.fecha_salida_albergue === 'object' ? 'color:#28B463':'color:#EC7063'" scope="row">{{item.documento_jefe_hogar}}</th>
            <td>{{item.tipo_documento}}</td>
            <td>{{ item.numero_documento }}</td>
            <td>{{ item.primer_nombre }} {{ item.segundo_nombre }}</td>
            <td>{{ item.primer_apellido }} {{ item.segundo_apellido }}</td>
            <td>{{item.codigo_fud}}</td>
            <td>{{item.fecha_ingreso_albergue}}</td>
            <td>{{item.hora_ingreso_albergue}}</td>
            <td>{{item.fecha_salida_albergue == '' ? 'No registra':typeof item.fecha_salida_albergue == 'object' ? 'No registra':item.fecha_salida_albergue}}</td>
            <td>{{item.hora_salida_albergue == '' ? 'No registra': typeof item.hora_salida_albergue == 'object' ? 'No registra':item.hora_salida_albergue}}</td>
            <td>{{convertirMoneda(item.valor_persona)}}</td>
            <td>Medellín</td>
            <td>Personería</td>
            <td>
              <button v-if="btn_actualizar_salida || btn_salida_persona && item.fecha_salida_albergue == '' || btn_salida_persona && typeof item.fecha_salida_albergue == 'object'" type="button" class="btn btn-primary btn-sm" @click="actualizar(item.id_ficha)">
                Salida
              </button>
            </td>
            <td>
              <button type="button" class="btn btn-warning btn-sm">AHI</button>
            </td>
          </tr>
        </tbody>
      </table>
      <!-- </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import Navbar from "../components/Navbar.vue";
import axios from "axios";

export default {
  components: {
    Navbar,
  },
  data() {
    return {
      personas: [],
      valor_minuto: "",
      valor_total_dia:0,
      numeroPersonas:[],
      familias:[],
      search:'',
      permisos:[],
      btn_ingreso_persona:false,
      btn_ingreso_albergue:false,
      btn_persona_ingreso:false,
      btn_salida_persona:false,
      URL_API:process.env.VUE_APP_URL_API,
      btn_actualizar_salida:false
    };
  },
  created() {
    this.getPersonas();
    this.userId()
  },
  methods: {
    newPerson() {
      this.$router.push("/insertarPersona");
    },
    newPerson2() {
      this.$router.push("/registroalbergue");
    },
    getPersonas() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API+"fichahogar/show",config)
        .then(function (result) {
          try {
            // console.log(result.data)
            self.personas = result.data;
            self.valor_minuto = result.data[0].valor_dia / 1440;
            // console.log('valor minuto', result.data[0].valor_dia / 1440);
            self.valorDia();
          } catch (error) {
            console.log(error);
          }
        });
    },
    calculaMinutos(fi, fs, hi, hs) {
      var fechaIngreso = new Date(
        fi.split("-")[0],
        fi.split("-")[1] - 1,
        fi.split("-")[2],
        hi.split(":")[0],
        hi.split(":")[1]
      );

      if (fs == "" ||  fs == null) {
        let date = new Date();
        fs = date;
      } else {
        fs = new Date(
          fs.split("-")[0],
          fs.split("-")[1] - 1,
          fs.split("-")[2],
          hs.split(":")[0],
          hs.split(":")[1]
        );
      }
     var minutos = fs.getTime() - fechaIngreso.getTime();
      // console.log('minutos',Math.round(minutos/60000))
      var minutos2 = Math.round(minutos/60000)
      // console.log('valor tiempo',Math.round(this.valor_minuto*minutos2))
      var valor_tiempo = Math.round(this.valor_minuto*minutos2)
      return (valor_tiempo);
     // this.valor_total_dia = parseFloat( this.valor_total_dia)+parseFloat(valor_tiempo)
      // var temporal = this.valor_total_dia

    },
    valorDia() {
      let self = this;
      var valor_total = 0
      this.personas.forEach(function (element) {
             
         var valor = self.calculaMinutos(
          element.fecha_ingreso_albergue,
          element.fecha_salida_albergue,
          element.hora_ingreso_albergue,
          element.hora_salida_albergue
        )
        element.valor_persona = valor
         if(element.fecha_salida_albergue == '' || element.fecha_salida_albergue == null){ 
        valor_total += valor
    
          // Valida el número de familias dentro del albergue sin repetirlas
          if(self.familias == []){
          self.familias.push(element.documento_jefe_hogar)
         }else{
           if(!self.familias.includes(element.documento_jefe_hogar)){
            self.familias.push(element.documento_jefe_hogar)
          }
         }

         // Valida el número de personas dentro del albergue sin repetirlas
          if(self.numeroPersonas == []){
          self.numeroPersonas.push(element.numero_documento)
         }else{
           if(!self.numeroPersonas.includes(element.numero_documento)){
            self.numeroPersonas.push(element.numero_documento)
          }
         }
         }
      });
      self.valor_total_dia = valor_total
      // self.numeroPersonas = personas
    },
    convertirMoneda(value){
      const formatterPeso = new Intl.NumberFormat('es-CO', {
       style: 'currency',
       currency: 'COP',
       minimumFractionDigits: 0
     })
       return formatterPeso.format(value)
    },
    searchWord(search){
       let self = this;
       let config = this.configHeader();
      axios
        .get(self.URL_API+"fichahogar/alberguebyid/"+search,config)
        .then(function (result) {
          try {
            // console.log(result.data)
            self.personas = result.data;
            self.valor_minuto = result.data[0].valor_dia / 1440;
            // console.log('valor minuto', result.data[0].valor_dia / 1440);
            self.valorDia();
          } catch (error) {
            console.log(error);
          }
        });
      console.log('prueba busqueda', search)
    },
    actualizar(documento){
         this.$router.push({ name: "Ficha", params: { id: documento, accion: 'actualizar' } });
    },
    userId() {
      let tokenuser = localStorage.getItem("access_token");
      let fragmentToken = atob(tokenuser.split(".")[1]);
      var data = JSON.parse(fragmentToken);
      this.getPermisos(data.sub)
    },
    getPermisos(id) {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API+"userpermiso/" + id,config)
        .then(function (result) {
          self.permisos = result.data;
          self.setPermisos(result.data)
        });
    },
    setPermisos(permisos){
      let self = this
      permisos.forEach(function(item){
        if(item.permiso == 'registrar persona'){
          self.btn_ingreso_persona = true
        }
        if(item.permiso =='registrar ingreso'){
          // console.log('permiso para ingreso albergue concedido')
        }        
        if(item.permiso =='registrar salida'){
          self.btn_salida_persona = true
        }        
        if(item.permiso =='registrar persona_ingreso'){
          self.btn_persona_ingreso = true
        }
        if(item.permiso == 'actualizar ficha ingreso'){
          self.btn_actualizar_salida = true
        }     
      })
    },
    configHeader() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token'),
        },
      };
      return config;
    },
  },
};
</script>
<style scoped>
.row {
  margin-top: 30px;
  margin-bottom: 30px;
}

h3,
h6 {
  display: block;
  right: 10px;
  text-align: left;
}

#search {
  width: 30%;
}

#search input {
  padding: 5px;
  box-sizing: border-box;
}
table {
  margin-top: 30px;
}
</style>
