<template>
  <div>
  <Navbar/>
  <div class="container">
    <h1>Bienvenidos a vidarF</h1>
  </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from '@/components/HelloWorld.vue'
import Navbar from '../components/Navbar.vue'

export default {
  name: 'Home',
  components: {
    Navbar
  }
}
</script>
<style scoped>
h1{
  margin-top: 30px;
}
</style>
