<template>
  <div>
    <navbar />
    <div class="container">
      <h3 style="float: left">Registro ingreso albergue</h3>
      <form style="clear: both" class="row g-3">
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Parentesco</label>
          <select
            id="inputState"
            class="form-select"
            v-model="parentesco"
            @change="parentescoId(parentesco)"
            :disabled="!actualizar_campo"
          >
            <option selected>Por favor seleccione el parentesco</option>
            <option v-for="item in parentescos" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >Número de documento jefe de hogar</label
          >
          <input
            type="text"
            v-model="documento_jefe_hogar"
            class="form-control"
            id="inputPassword4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div v-if="parentesco == 'Jefe de hogar'" class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >Número de integrantes en declaración</label
          >
          <input
            type="text"
            v-model="integrantes_declaracion"
            class="form-control"
            id="inputPassword4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div v-if="parentesco == 'Jefe de hogar'" class="col-md-6">
          <label for="inputEmail4" class="form-label"
            >Número de integrantes que ingresan</label
          >
          <input
            type="text"
            v-model="integrantes_ingresan"
            class="form-control"
            id="inputEmail4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Sede albergue</label>
          <select
            id="inputState"
            class="form-select"
            v-model="sede"
            @change="sedeId(sede)"
            :disabled="!actualizar_campo"
          >
            <option selected>Por favor seleccione una sede</option>
            <option v-for="item in sedes" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >Fecha de ingreso</label
          >
          <input
            type="date"
            v-model="fecha_ingreso"
            class="form-control"
            id="inputPassword4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Hora de ingreso </label>
          <input
            type="time"
            v-model="hora_ingreso"
            class="form-control"
            id="inputEmail4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label"
            >Entidad de declaración</label
          >
          <select
            id="inputState"
            class="form-select"
            v-model="entidad_declracion"
            @change="entidadDeclaracionId(entidad_declracion)"
            :disabled="!actualizar_campo"
          >
            <option selected>Por favor seleccione una entidad</option>
            <option v-for="item in declaracion_entidades" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >Fecha de declaración</label
          >
          <input
            type="date"
            v-model="fecha_declaracion"
            class="form-control"
            id="inputPassword4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label"
            >Departamento de ocurrencia</label
          >
          <select
            id="inputState"
            class="form-select"
            v-model="departamento"
            @change="departamentoId(departamento)"
            :disabled="!actualizar_campo"
          >
            <option selected>Por favor seleccione un municipio</option>
            <option v-for="item in departamentos" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label"
            >Municipio de ocurrencia</label
          >
          <select
            id="inputState"
            class="form-select"
            v-model="municipio"
            @change="municipioId(municipio)"
            :disabled="!actualizar_campo"
          >
            <option selected>Por favor seleccione un municipio</option>
            <option v-for="item in municipios" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Estado en vivanto</label>
          <select
            id="inputState"
            class="form-select"
            v-model="estado_vivanto"
            @change="estadoVivantoId(estado_vivanto)"
            :disabled="!actualizar_campo"
          >
            <option selected>Por favor seleccione un estado</option>
            <option v-for="item in vivanto_estados" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >Fecha estado vivanto</label
          >
          <input
            type="date"
            v-model="fecha_estado_vivanto"
            class="form-control"
            id="inputPassword4"
            :disabled="!actualizar_campo"
          />
        </div>
        <div v-if="$route.params.accion == 'actualizar'" class="col-md-6">
          <label for="inputPassword4" class="form-label">Fecha de salida</label>
          <input
            type="date"
            v-model="fecha_salida"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div v-if="$route.params.accion == 'actualizar'" class="col-md-6">
          <label for="inputPassword4" class="form-label">Hora de salida</label>
          <input
            type="time"
            v-model="hora_salida"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div v-if="$route.params.accion == 'actualizar'" class="col-md-6">
          <label for="inputEmail4" class="form-label">Motivo de salida</label>
          <select
            id="inputState"
            class="form-select"
            v-model="motivo_salida"
            @change="motivoSalidaId(motivo_salida)"
          >
            <option selected>Por favor seleccione una opción</option>
            <option v-for="item in salida_motivos" :key="item.id">
              {{ item.motivo_salida }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Observaciones</label>
          <textarea
            type="text"
            v-model="descripcion_motivo_salida"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-12">
          <button type="button" class="btn btn-success" @click="insertFicha">
            Guardar
          </button>
          <button type="button" class="btn btn-warning">Limpiar</button>
          <button type="button" class="btn btn-primary" @click="back()">
            Atrás
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue";
import axios from "axios";
export default {
  components: { Navbar },
  data() {
    return {
      integrantes_declaracion: "",
      integrantes_ingresan: "",
      documento_jefe_hogar: "",
      fecha_ingreso: "",
      hora_ingreso: "",
      parentesco: "",
      fecha_salida: "",
      hora_salida: "",
      fecha_estado_vivanto: "",
      motivo_salida: "",
      descripcion_motivo_salida: "",
      sede: "",
      municipio: "",
      departamento: "",
      entidad_declracion: "",
      fecha_declaracion: "",
      estado_vivanto: "",
      parentescos: [],
      sedes: [],
      municipios: [],
      departamentos: [],
      declaracion_entidades: [],
      vivanto_estados: [],
      salida_motivos: [],
      parentescoId_: "",
      sedeId_: "",
      municipioId_: "",
      departamentoId_: "",
      declaracion_entidadesId_: "",
      vivanto_estadosId_: "",
      motivo_salidaId_: null,
      documento: "",
      id: "",
      accion: "",
      id_ficha_hogar: "",
      URL_API: process.env.VUE_APP_URL_API,
      id_motivo_salida_:"",
      id_entidad_declaracion_:"",
      id_municipio_ocurrencia_:"",
      id_estado_vivanto_:"",
      id_albergue_sedes_:"",
      id_parentesco_:"",
      id_persona_:"",
      actualizar_campo:false,
      permisos:[],
    };
  },
  created() {
    // this.documento = localStorage.getItem('documento')
    this.getParentescos();
    this.getSedes();
    this.getDepartamentos();
    this.getEntidadesDeclaracion();
    this.getEstados_vivanto();
    this.getIdMotivoSalida();
    if (this.$route.params.accion == "actualizar") {
      this.id = this.$route.params.id;
      this.accion = this.$route.params.accion;
      this.getPersonaFicha(this.$route.params.id);
      this.userId()
    } else {
      this.id = this.$route.params.id;
      this.getIdPersona();
    }if (this.$route.params.accion == "insertar") {
      this.actualizar_campo = true
    }
  },
  methods: {
    insertFicha() {
      let self = this;
      let ficha;
      let config = this.configHeader();
      var ruta;
      if (this.$route.params.accion == "actualizar") {
        ficha = {
          integrantes_declaracion: self.integrantes_declaracion,
          integrantes_ingresan_albergue: self.integrantes_ingresan,
          documento_jefe_hogar: self.documento_jefe_hogar,
          fecha_ingreso_albergue: self.fecha_ingreso,
          hora_ingreso_albergue: self.hora_ingreso,
          fecha_salida_albergue: self.fecha_salida,
          hora_salida_albergue: self.hora_salida,
          descripcion_motivo_salida: self.descripcion_motivo_salida,
          fecha_declaracion: self.fecha_declaracion,
          fecha_estado_vivanto: self.fecha_estado_vivanto,
          id_motivo_salida: self.motivo_salidaId_,
          id_entidad_declaracion: self.declaracion_entidadesId_,
          id_municipio_ocurrencia: self.municipioId_,
          id_estado_vivanto: self.vivanto_estadosId_,
          id_albergue_sedes: self.sedeId_,
          id_parentesco: self.parentescoId_,
          id_persona: self.id_persona_,
        };
        ruta = "fichahogar/update/" + self.id_ficha_hogar;
      } else {
        ficha = {
          integrantes_declaracion: this.integrantes_declaracion,
          integrantes_ingresan_albergue: this.integrantes_ingresan,
          documento_jefe_hogar: this.documento_jefe_hogar,
          id_albergue_sedes: this.sedeId_,
          fecha_ingreso_albergue: this.fecha_ingreso,
          hora_ingreso_albergue: this.hora_ingreso,
          fecha_salida_albergue: this.fecha_salida,
          hora_salida_albergue: this.hora_salida,
          id_motivo_salida: 1,
          descripcion_motivo_salida: this.descripcion_motivo_salida,
          id_entidad_declaracion: this.declaracion_entidadesId_,
          id_municipio_ocurrencia: this.municipioId_,
          id_departamento_ocurrencia: this.departamentoId_,
          fecha_declaracion: this.fecha_declaracion,
          id_estado_vivanto: this.vivanto_estadosId_,
          fecha_estado_vivanto: this.fecha_estado_vivanto,
          id_parentesco: this.parentescoId_,
          id_persona: this.id,
        };
        ruta = "fichahogar/insert";
      }

      axios.post(self.URL_API + ruta, ficha, config).then(function (result) {
        try {
          console.log(result.data);
          self.message("success", "Registro insertado exitosamente");
          self.$router.push("/albergues");
        } catch (error) {
          console.log(error);
        }
      });
    },
    getIdPersona() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "persona/getbyid/" + this.id, config)
        .then(function (result) {
          self.id = result.data[0].id;
        })
        .catch(function (error) {
          // self.getIdPersona()
          console.log(error);
        });
    },
    getParentescos() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "parentesco", config)
        .then(function (result) {
          self.parentescos = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getParentescos();
        });
    },
    getSedes() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "sedealbergue", config)
        .then(function (result) {
          self.sedes = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getSedes();
        });
    },
    getMunicipios(id) {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "municipio/" + id, config)
        .then(function (result) {
          self.municipios = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getMunicipios(id);
        });
    },
    getDepartamentos() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "departamento", config)
        .then(function (result) {
          self.departamentos = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getDepartamentos();
        });
    },
    getEntidadesDeclaracion() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "entidadeclaracion", config)
        .then(function (result) {
          self.declaracion_entidades = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getEntidadesDeclaracion();
        });
    },
    getEstados_vivanto() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "estadovivanto", config)
        .then(function (result) {
          self.vivanto_estados = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getEstados_vivanto();
        });
    },
    getIdMotivoSalida() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "motivosalida", config)
        .then(function (result) {
          self.salida_motivos = result.data;
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getIdMotivoSalida();
        });
    },
    userId() {
      let tokenuser = localStorage.getItem("access_token");
      let fragmentToken = atob(tokenuser.split(".")[1]);
      var data = JSON.parse(fragmentToken);
      this.getPermisos(data.sub)
    },
    getPermisos(id) {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API+"userpermiso/" + id,config)
        .then(function (result) {
          self.permisos = result.data;
          self.setPermisos(result.data)
        });
    },
     setPermisos(permisos){
      let self = this
      permisos.forEach(function(item){
        if(item.permiso == 'actualizar ficha ingreso'){
          self.actualizar_campo = true
        }
      })
    },
    getPersonaFicha(id) {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API + "fichahogar/albergue/" + id, config)
        .then(function (result) {
          console.log(result.data);
          self.integrantes_declaracion = result.data[0].integrantes_declaracion;
          self.integrantes_ingresan = result.data[0].integrantes_ingresan_albergue;
          self.documento_jefe_hogar = result.data[0].documento_jefe_hogar;
          self.fecha_ingreso = result.data[0].fecha_ingreso_albergue;
          self.hora_ingreso = result.data[0].hora_ingreso_albergue;
          self.parentesco = result.data[0].parentesco;
          self.fecha_salida = result.data[0].fecha_salida_albergue;
          self.hora_salida = result.data[0].hora_salida_albergue;
          self.motivo_salida = result.data[0].motivo_salida;
          self.descripcion_motivo_salida = result.data[0].descripcion_motivo_salida
          self.sede = result.data[0].sede_albergue;
          self.municipio = result.data[0].municipio;
          self.departamento = result.data[0].departamento;
          self.entidad_declracion = result.data[0].entidad_declaracion;
          self.fecha_declaracion = result.data[0].fecha_declaracion;
          self.estado_vivanto = result.data[0].estado_vivanto;
          self.fecha_estado_vivanto = result.data[0].fecha_estado_vivanto;
          self.id_ficha_hogar = result.data[0].id;
          self.motivo_salidaId_ = result.data[0].id_motivo_salida
          self.declaracion_entidadesId_ = result.data[0].id_entidad_declaracion
          self.municipioId_ = result.data[0].id_municipio_ocurrencia
          self.vivanto_estadosId_ = result.data[0].id_estado_vivanto
          self.sedeId_ = result.data[0].id_albergue_sedes
          self.parentescoId_ = result.data[0].id_parentesco
          self.id_persona_ = result.data[0].id_persona
          self.getMunicipios(result.data[0].id_departamento);
        })
        .catch(function (error) {
          if (error.name == "AxiosError") self.getPersonaFicha(id);
        });
    },
    parentescoId(parentesco) {
      let self = this;
      this.parentescos
        .forEach(function (element) {
          if (parentesco == element.nombre) {
            self.parentescoId_ = element.id;
          }
        })
        .catch(function (error) {
          self.parentescoId();
          console.log(error);
        });
    },
    sedeId(sede) {
      let self = this;
      this.sedes.forEach(function (element) {
        if (sede == element.nombre) {
          self.sedeId_ = element.id;
        }
      });
    },
    municipioId(municipio) {
      let self = this;
      this.municipios.forEach(function (element) {
        if (municipio == element.nombre) {
          self.municipioId_ = element.id;
        }
      });
    },
    departamentoId(departamento) {
      let self = this;
      this.departamentos.forEach(function (element) {
        if (departamento == element.nombre) {
          self.departamentoId_ = element.id;
          self.getMunicipios(self.departamentoId_);
        }
      });
    },
    entidadDeclaracionId(entidad) {
      let self = this;
      this.declaracion_entidades.forEach(function (element) {
        if (entidad == element.nombre) {
          self.declaracion_entidadesId_ = element.id;
        }
      });
    },
    estadoVivantoId(vivanto) {
      let self = this;
      this.vivanto_estados.forEach(function (element) {
        if (vivanto == element.nombre) {
          self.vivanto_estadosId_ = element.id;
        }
      });
    },
    motivoSalidaId(motivo) {
      let self = this;
      this.salida_motivos.forEach(function (element) {
        if (motivo == element.motivo_salida) {
          self.motivo_salidaId_ = element.id;
        }
      });
    },
    message(icon, title) {
      this.$swal.fire({
        position: "top",
        icon: icon,
        title: title,
        showConfirmButton: false,
        timer: 2000,
      });
    },
    back() {
      this.$router.go(-1)
    },
    configHeader() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem("access_token"),
        },
      };
      return config;
    },
  },
};
</script>
<style scoped>
.container {
  margin-top: 30px;
}

label {
  float: left;
}
.form-check {
  margin-left: 10px;
}

button {
  float: left;
  margin-right: 15px;
}
.col-12 {
  margin-bottom: 30px;
}
</style>
