<template>
    <div>
      <Persona/>
    </div>
</template>
<script>
import Persona from '../components/Persona.vue'

export default ({
  components: {
    Persona,
  },
    data() {
      return{
        
      }  
    },
})
</script>
<style scoped>

</style>