import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import Navbar from '../components/Navbar.vue'
import Albergues from '../components/Albergues.vue'
import Persona from '../components/Persona.vue'
import Ficha from '../components/Ficha.vue'
import Registroalbergue from '../components/Registroalbergue.vue'
import Register from '../components/Register.vue'
import InsertarPersona from '../views/InsertarPersona.vue'

Vue.use(VueRouter)

const routes = [
  
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/navbar',
    name: 'Navbar',
    component: Navbar
  },
  {
    path: '/albergues',
    name: 'Albergues',
    component: Albergues
  },
  {
    path: '/home',
    name: 'Home',
    component: Home
  },
  {
    path: '/persona',
    name: 'Persona',
    component: Persona
  },
  {
    path: '/ficha/:id/:accion',
    name: 'Ficha',
    component: Ficha
  },
  {
    path: '/insertarficha',
    name: 'InsertarFicha',
    component: Ficha
  },
  {
    path: '/actualizarficha/:id',
    name: 'ActualizarFicha',
    component: Ficha
  },
  {
    path: '/registroalbergue',
    name: 'Registroalbergue',
    component: Registroalbergue
  },
  {
    path: '/register',
    name: 'Register',
    component: Register
  },
  {
    path: '/insertarPersona',
    name: 'InsertarPersona',
    component: InsertarPersona
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  // mode: 'history',
  // base: process.env.BASE_URL,
  routes
})

export default router
