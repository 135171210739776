<template>
  <div>
    <navbar />
    <div class="container">
      <h3 style="float:left">Registro personas</h3>
      <form style="clear:both" class="row g-3">
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >numero de documento</label
          >
          <input
            type="text"
            v-model="numero_documento"
            class="form-control"
            id="inputPassword4"
            @blur="validarId()"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Código fud</label>
          <input
            type="text"
            v-model="codigo_fud"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Tipo de documento</label>
          <select
            id="inputState"
            class="form-select"
            v-model="tipo_documento"
            @change="tipoDocumentoId(tipo_documento)"
          >
            <option selected>Por favor seleccione el tipo de documento</option>
            <option v-for="item in tipoDocumentos" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Primer nombre</label>
          <input
            type="text"
            v-model="primer_nombre"
            class="form-control"
            id="inputEmail4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Segundo nombre</label>
          <input
            type="text"
            v-model="segundo_nombre"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">primer apellido</label>
          <input
            type="text"
            v-model="primer_apellido"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >Segundo apellido</label
          >
          <input
            type="text"
            v-model="segundo_apellido"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label"
            >Fecha de nacimiento</label
          >
          <input
            type="date"
            v-model="fecha_nacimiento"
            class="form-control"
            id="inputEmail4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Género</label>
          <select
            id="inputState"
            class="form-select"
            v-model="genero"
            @change="generoId(genero)"
          >
            <option selected>Por favor selecione el género</option>
            <option v-for="item in generos" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Pais de origen</label>
          <select
            id="inputState"
            class="form-select"
            v-model="nacionalidad"
            @change="nacionalidadId(nacionalidad)"
          >
            <option selected>Por favor selecione la nacionalidad</option>
            <option v-for="item in nacionalidades" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-12">
          <button
            type="button"
            class="btn btn-success"
            @click="insertarPersona"
          >
            Guardar
          </button>
          <button type="button" class="btn btn-warning">Limpiar</button>
          <button type="button" class="btn btn-primary" @click="back()">
            Atrás
          </button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import Navbar from "./Navbar.vue";
import axios from "axios";

export default {
  components: { Navbar },
  data() {
    return {
      codigo_fud: "",
      // documento_jefe_hogar: "",
      numero_documento: "",
      primer_nombre: "",
      segundo_nombre: "",
      primer_apellido: "",
      segundo_apellido: "",
      fecha_nacimiento: "",
      tipo_documento: "",
      genero: "",
      nacionalidad: "",
      tipoDocumentos: [],
      generos: [],
      nacionalidades: [],
      tipo_documento_: "",
      genero_: "",
      nacionalidad_: "",
      id:'',
      datos_persona:{},
      datos_persona_actualizado:{},
      persona_actualizada:false,
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created() {
    this.getTipoDocumento();
    this.getNacionalidad();
    this.getGenero();
    this.documento_ = localStorage.getItem("documento");
  },
  methods: {
    nacionalidadId(nacionalidad) {
      let self = this;
      this.nacionalidades.forEach(function (element) {
        if (nacionalidad == element.nombre) {
          self.nacionalidad_ = element.id;
        }
      });
    },
    generoId(genero) {
      let self = this;
      this.generos.forEach(function (element) {
        if (genero == element.nombre) {
          self.genero_ = element.id;
        }
      });
    },
    tipoDocumentoId(documento) {
      let self = this;
      this.tipoDocumentos.forEach(function (element) {
        if (documento == element.nombre) {
          self.tipo_documento_ = element.id;
        }
      });
    },
    insertarPersona() {
      let self = this
      let username = {
        codigo_fud: this.codigo_fud,
        // documento_jefe_hogar: this.documento_jefe_hogar,
        id_tipo_documento: this.tipo_documento_,
        numero_documento: this.numero_documento,
        primer_nombre: this.primer_nombre,
        segundo_nombre: this.segundo_nombre,
        primer_apellido: this.primer_apellido,
        segundo_apellido: this.segundo_apellido,
        fecha_nacimiento: this.fecha_nacimiento,
        id_genero: this.genero_,
        id_nacionalidad: this.nacionalidad_,
      };
      if(this.persona_actualizada){
        let config = this.configHeader();
         axios
        .post(self.URL_API+"persona/update/"+this.id, username,config)
        .then(function (result) {
          try {
            console.log(result.data);
              // self.validarId()
               if(self.id != 0){
                 self.$router.push({ name: "Ficha", params: { id: self.id, accion: 'insertar' } });
               }else{
                 self.$router.push({ name: "Ficha", params: { id: self.numero_documento, accion: 'insertar' } });
               }
              self.message("success","Persona insertada exitosamente")
          } catch (error) {
            console.log(error);
          }
        });
      }else{
        let config = this.configHeader();
         axios
        .post(self.URL_API+"persona/insert", username,config)
        .then(function (result) {
          try {
            console.log(result.data);
              // self.validarId()
               if(self.id != 0){
                 self.$router.push({ name: "Ficha", params: { id: self.id, accion: 'insertar' } });
               }else{
                 self.$router.push({ name: "Ficha", params: { id: self.numero_documento, accion: 'insertar' } });
               }
              self.message("success","Persona insertada exitosamente")
          } catch (error) {
            console.log(error);
          }
        });
      }
     
    },
    getNacionalidad() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API+"nacionalidad",config)
        .then(function (result) {
          try {
            // console.log(result.data);
            self.nacionalidades = result.data;
          } catch (error) {
            console.log(error);
          }
        });
    },
    getTipoDocumento() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API+"tipodocumento",config)
        .then(function (result) {
          try {
            // console.log(result.data);
            self.tipoDocumentos = result.data;
          } catch (error) {
            console.log(error);
          }
        });
    },
    getGenero() {
      let self = this;
      let config = this.configHeader();
      axios
        .get(self.URL_API+"genero",config)
        .then(function (result) {
          try {
            // console.log(result.data);
            self.generos = result.data;
          } catch (error) {
            console.log(error);
          }
        });
    },
    validarId() {
      let self = this;
      if(self.numero_documento != '' || self.numero_documento != null){
        let config = this.configHeader();
      axios
        .get(
          self.URL_API+"persona/getbyid/" +
            self.numero_documento,config
        )
        .then(function (result) {
          try {
            if (typeof result.data[0] != "undefined") {
              self.id = result.data[0].id
              self.datos_persona = result.data[0]
                self.message2("Esta persona ya se encuentra registrada")
            }else{
                self.id = 0
            }
          } catch (error) {
            console.log(error);
          }
        });
      }
    },
    actualizar(){
          this.id = this.datos_persona.id
          this.codigo_fud = this.datos_persona.codigo_fud
          // this.documento_jefe_hogar = this.datos_persona.documento_jefe_hogar
          this.fecha_nacimiento = this.datos_persona.fecha_nacimiento
          this.genero = this.datos_persona.genero
          this.nacionalidad = this.datos_persona.nacionalidad
          this.primer_nombre = this.datos_persona.primer_nombre
          this.segundo_nombre = this.datos_persona.segundo_nombre
          this.primer_apellido = this.datos_persona.primer_apellido
          this.segundo_apellido = this.datos_persona.segundo_apellido
          this.tipo_documento = this.datos_persona.tipo_documento
          this.numero_documento = this.datos_persona.numero_documento
          this.tipo_documento_ = this.datos_persona.id_tipo_documento
          this.genero_ = this.datos_persona.id_genero,
          this.nacionalidad_ = this.datos_persona.id_nacionalidad,
          this.persona_actualizada = true
    },
    message2(title) {
      let self = this
      this.$swal.fire({
        title: title,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Actualizar",
        denyButtonText: `llenar ingreso`,
        icon: "info",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          // this.$swal.fire("Saved!", "", "success");
          self.actualizar()
        } else if (result.isDenied) {
          // this.$swal.fire("Changes are not saved", "", "info");
           this.$router.push({ name: "Ficha", params: { id: self.id, accion: 'insertar' } });
        }
      });
    },
     message(icon, title) {
      this.$swal.fire({
        position: "top",
        icon: icon,
        title: title,
        showConfirmButton: false,
        timer: 2000,
      });
     },
    back() {
      this.$router.go(-1)
    },
    configHeader() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token'),
        },
      };
      return config;
    },
  },
};
</script>
<style scoped>
.container {
  margin-top: 30px;
}

label {
  float: left;
}
.form-check {
  margin-left: 10px;
}

button {
  float: left;
  margin-right: 15px;
}
.col-12 {
  margin-bottom: 30px;
}
</style>
