<template>
    <div>
         <navbar />
        <div class="container">
      <h3 style="float:left">Registro personas</h3>
      <form style="clear:both" class="row g-3">
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label"
            >numero de documento</label
          >
          <input
            type="text"
            v-model="numero_documento"
            class="form-control"
            id="inputPassword4"
            @blur="validarId()"
          />
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Tipo de documento</label>
          <select
            id="inputState"
            class="form-select"
            v-model="tipo_documento"
            @change="tipoDocumentoId(tipo_documento)"
          >
            <option selected>Por favor seleccione el tipo de documento</option>
            <option v-for="item in tipoDocumentos" :key="item.id">
              {{ item.nombre }}
            </option>
          </select>
        </div>
        <div class="col-md-6">
          <label for="inputEmail4" class="form-label">Primer nombre</label>
          <input
            type="text"
            v-model="primer_nombre"
            class="form-control"
            id="inputEmail4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Segundo nombre</label>
          <input
            type="text"
            v-model="segundo_nombre"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">primer apellido</label>
          <input
            type="text"
            v-model="primer_apellido"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Segundo apellido</label>
          <input
            type="text"
            v-model="segundo_apellido"
            class="form-control"
            id="inputPassword4"
          />
        </div>
         <div class="col-md-6">
            <label for="inputPassword4" class="form-label">Fecha de ingreso</label>
            <input type="date" v-model="fecha_ingreso" class="form-control" id="inputPassword4" />
          </div>
          <div class="col-md-6">
            <label for="inputEmail4" class="form-label">Hora de ingreso </label>
            <input type="time" v-model="hora_ingreso" class="form-control" id="inputEmail4" />
          </div>
           <div v-if="parentesco == 'Jefe de hogar'" class="col-md-6">
            <label for="inputEmail4" class="form-label">Número de integrantes que ingresan</label>
            <input type="text" v-model="integrantes_ingresan" class="form-control" id="inputEmail4" />
          </div>
           <div class="col-md-6">
          <label for="inputPassword4" class="form-label">Parentesco</label>
          <input
            type="text"
            v-model="parentesco"
            class="form-control"
            id="inputPassword4"
          />
        </div>
        <div class="col-12">
          <button
            type="button"
            class="btn btn-success"
            @click="insertarPersona"
          >
            Guardar
          </button>
          <button type="button" class="btn btn-warning">Limpiar</button>
          <button type="button" class="btn btn-primary" @click="back()">
            Atrás
          </button>
        </div>
      </form>
    </div>
    </div>
</template>
<script>
// import axios from 'axios';
import Navbar from "./Navbar.vue";

export default ({
    components: { Navbar },
    data() {
        return{
            parentesco:'Jefe de hogar',
            URL_API:process.env.VUE_APP_URL_API
        } 
    },
    created(){

    },
    methods:{
        validarId(){

        },
        tipoDocumentoId(tipoDocumento){
            console.log(tipoDocumento)
        },
        insertarPersona(){

        },
        back(){

        },
    }
})
</script>
<style scoped>
.container{
    margin-top: 30px;
}

label{
    float: left;
}
.form-check{
    margin-left: 10px;
}

button{
    float: left;
    margin-right: 15px;
}
.col-12{
  margin-bottom: 30px;
}
</style>