<template>
  <div class="container">
    <div class="logo">
      <img src="@/assets/logo.png" alt="" />
    </div>
    <h2>Iniciar sesión</h2>
    <div class="card">
      <form>
        <div class="mb-3">
          <label for="exampleInputEmail1" class="form-label"
            >Correo electrónico</label
          >
          <input
            type="email"
            class="form-control"
            id="exampleInputEmail1"
            aria-describedby="prueba"
            v-model="email"
          />
          <!-- <div id="emailHelp" class="form-text">
            {{ message }}
          </div> -->
        </div>
        <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label"
            >Contraseña</label
          >
          <input
            type="password"
            class="form-control"
            id="exampleInputPassword1"
            v-model="password"
          />
        </div>
        <!-- <div class="mb-3">
          <label for="exampleInputPassword1" class="form-label"
            >Sede</label
          >
          <select class="form-select" aria-label="Default select example">
            <option selected>Por favor seleccione una opción</option>
            <option value="1Belencito">Belencito</option>
            <option value="Juanes">Juanes</option>
            <option value="Caunces">Caunces</option>
            <option value="UPJ">UPJ</option>
            <option value="Albergue">Albergue</option>
          </select>
        </div> -->
        <button type="button" class="btn btn-success" @click="login()">
          Ingresar
        </button>
      </form>
    </div>
  </div>
</template>
<script>
 import axios from 'axios';

export default {
  data() {
    return {
      email: "",
      password: "",
      // message: "",
      URL_API:process.env.VUE_APP_URL_API
    };
  },
  created(){
  },
  methods: {
    login() {
      let self = this
      let username = {email:this.email, password: this.password}
        axios.post(self.URL_API+'login',username)
      .then(function (result){
          if(result.data.access_token != undefined){
          localStorage.setItem('access_token',result.data.access_token)
            self.$router.push('/home')
        }else{
            console.log(result)
          // self.message = result.data.message
          self.message('error','Este usuario se encuentra deshabilitado por favor comuniquese con el administrador del sistema')
        }
      })
    },
     message(icon, title) {
      this.$swal.fire({
        position: "top",
        icon: icon,
        title: title,
        showConfirmButton: false,
        timer: 3000,
      });
     },
  },
};
</script>
<style scoped>
.card {
  width: 35%;
  height: 260px;
  margin: auto;
  padding: 20px;
  background-color: rgba(239, 237, 237, 0.642);
  min-width: 350px;
  margin-bottom: 30px;
}

label {
  float: left;
}

.form-control {
  background-color: white;
}

h2 {
  font-family: "Montserrat", sans-serif;
  margin: 20px 0px 20px 0px;
}

button {
  width: 100%;
}
.logo {
  width: 150px;
  margin: auto;
  border-radius: 15px;
  margin-top: 50px;
}
.logo img {
  width: 100%;
  border-radius: 15px;
}

#emailHelp {
  color: red;
}
</style>
