<template>
  <div>
    <nav
      class="navbar navbar-expand-lg navbar-dark"
      style="background-color: #465c8b; heigth: 100px"
    >
      <div class="container-fluid">
        <a class="navbar-brand" href="">
          <img
            src="@/assets/logo.png"
            alt=""
            width="50px"
            class="d-inline-block align-text-top"
          />
          <!-- Acá va el nombre-->
        </a>
        <button
          class="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          @click="collapese"
        >
          <span class="navbar-toggler-icon"></span>
        </button>
        <div
          :class="
            collapse
              ? 'collapse navbar-collapse show'
              : 'collapse navbar-collapse'
          "
          id="navbarNav"
        >
          <ul class="navbar-nav">
            <li class="nav-item" @click="collapese">
              <router-link class="nav-link active" to=""
                >{{ saludo }} <i class="bi bi-emoji-smile-fill"></i>
                {{ userlogued.usuario }}</router-link
              >
            </li>
            <li class="nav-item logout" id="logout" @click="logout">
              <!-- <router-link class="nav-link active"> -->
                <i class="bi bi-box-arrow-left"></i> Cerrar sesión
                <!-- </router-link> -->
              
            </li>
          </ul>
        </div>
      </div>
    </nav>
    <!-- <div v-bind:class="!expand ? 'aside' : 'aside2'"> -->
    <div class="aside">
      <div v-for="item in menu" :key="item.nombre">
        <!-- <div v-if="item.urlExterna == '1'">Hola</div> -->
        <router-link
          v-if="item.urlExterna == '0'"
          class="nav-link active"
          :to="item.url"
        >
          <i :class="item.icon"></i> <span>{{ item.nombre == 'rol'? 'Rol: '+userlogued.rol: item.nombre }}</span>
        </router-link>
        <a
          v-else
          :href="item.url"
          target="_blank"
          rel="noopener noreferrer"
          style="color: white; text-decoration: none"
          ><i :class="item.icon"></i> <span>{{ item.nombre }}</span></a
        >
        <hr />
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";

export default {
  data() {
    return {
      username: "",
      collapse: false,
      expand: false,
      saludo: "Bienvenido",
      ruta: "",
      userlogued: {},
      menu:[],
      URL_API:process.env.VUE_APP_URL_API
      // menu: [
      //   { nombre: "", link: "", icon: "", urlExterna: "0" },
      //   {
      //     nombre: "Bienvenido",
      //     link: "/home",
      //     icon: "bi bi-text-indent-left",
      //     urlExterna: "0",
      //   },
      //   { nombre: "rol", link: "", icon: "bi bi-key", urlExterna: "0" },
      //   {
      //     nombre: "Caracterización DFI",
      //     link: "",
      //     icon: "bi bi-people",
      //     urlExterna: "0",
      //   },
      //   {
      //     nombre: "Revisión DX",
      //     link: "",
      //     icon: "bi bi-journal-check",
      //     urlExterna: "0",
      //   },
      //   {
      //     nombre: "Albergues",
      //     link: "/albergues",
      //     icon: "bi bi-file-earmark-richtext",
      //     urlExterna: "0",
      //   },
      //   {
      //     nombre: "Albergue control",
      //     link: "",
      //     icon: "bi bi-cash-coin",
      //     urlExterna: "0",
      //   },
      //   {
      //     nombre: "Configuración",
      //     link: "",
      //     icon: "bi bi-wrench",
      //     urlExterna: "0",
      //   },
      //   {
      //     nombre: "Acerca de",
      //     link: "",
      //     icon: "bi bi-info-circle",
      //     urlExterna: "1",
      //   },
      // ],
    };
  },
  created() {
    this.username = localStorage.getItem("username");
    this.ruta = this.$route.name;
    this.userId();
  },
  methods: {
    collapese() {
      this.collapse = !this.collapse;
    },
    logout() {
      // let self = this
      //   let config = this.configHeader();
      //   axios
      //     .post(self.URL_API+"logout",config)
      //     .then(function (result) {
      //       console.log(result.data)
      //     });
            this.$router.push("/");
            localStorage.removeItem("access_token");
    },
    getMenu(id) {
      let self = this
       let config = this.configHeader();
        axios
          .get(self.URL_API+"menu/"+id,config)
          .then(function (result) {
            self.menu = result.data            
          }).catch(function (error) {
          if(error.name == 'AxiosError')
          self.getMenu(id)
        });
    },
    asideExpand() {
      this.expand = !this.expand;
      console.log(this.expand);
    },
    userId() {
      let tokenuser = localStorage.getItem("access_token");
      let fragmentToken = atob(tokenuser.split(".")[1]);
      var data = JSON.parse(fragmentToken);
      this.userLogued(data.sub);
    },
    userLogued(id) {
      let self = this;
       let config = this.configHeader();
      axios
        .get(self.URL_API+"userlogued/" + id,config)
        .then(function (result) {
          self.userlogued = result.data[0];
           self.getMenu(self.userlogued.id)
        });
    },
    configHeader() {
      let config = {
        headers: {
          Authorization: "Bearer " + localStorage.getItem('access_token'),
        },
      };
      return config;
    },
  },
};
</script>
<style scoped>
.navbar {
  box-shadow: 3px 3px 4px 2px rgba(26, 25, 25, 0.338);
}

.navbar-brand {
  margin-left: 50px;
}

.aside {
  width: 50px;
  height: 100vh;
  background-color: #465c8b;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 200;
  color: white;
  transition: width 1s;
  overflow-x: hidden;
  font-size: 1.1rem;
  padding: 10px;
  text-align: left;
  border-bottom-right-radius: 5px;
  box-shadow: 3px 3px 4px 2px rgba(26, 25, 25, 0.338);
}

.aside:hover::-webkit-scrollbar-thumb {
  visibility: visible;
  transition: width 1s;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #38538d;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #2c4171;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6f7584;
}

::-webkit-scrollbar-thumb {
  visibility: hidden;
}

.aside i {
  position: absolute;
  left: 15px;
}

.aside span {
  position: relative;
  margin-left: 40px;
  white-space: nowrap;
}

.aside:hover {
  width: 240px;
}

.aside h1 i {
  cursor: pointer;
}

.aside #icon {
  margin-left: 10px;
}

#logout {
  position: absolute;
  right: 20px;
  color: white;
  padding: 10px;
  cursor: pointer;
}

@media screen and (max-width: 991px) {
  #logout {
    position: relative;
    /* right: 0px; */
  }

  /* #menucolapsed{
    display:block;
  } */
}

@media screen and (min-width: 991px) {
  .navbar #menucolapsed {
    display: none;
  }
}
</style>
